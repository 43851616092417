:root {
  --gold: rgb(214 146 83);
  --silver: #9a9a9a;
  --red: #c13939;
}



@import "~@fontsource/montserrat/index.css";

h1 {
  text-transform: uppercase;
  font-size: 4em;
  font-family: bebas-neue;
  letter-spacing: 0.1em;
  font-weight: lighter;
  display: flex;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
}

.app-container {
  font-family: "montserrat", "Perpetua", "Gothic A1", sans-serif;
  font-weight: lighter;
}

.watch-page-container {
  width: calc(100%);
  margin: 10px auto 20px;
}

@media (min-width: 550px) {
  .watch-page-container {
    width: calc(75%);
    margin: 10px auto 20px;
  }
}

.video-container {
  width: calc(100%);
  margin: 0 auto;
}

.video-filter {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: #15141475;
  opacity: 1;
}

.video-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-image: url(./assets/grid.png);
  opacity: 1;
}

.video-title {
  position: absolute;
  width: calc(100% - 40px);
  margin: 0 auto;
  top: 25%;
  font-size: 1.5em;
  font-family: "Perpetua", "Gothic A1", sans-serif;
  font-weight: normal;
  color: white;
  text-align: center;
  letter-spacing: -0.01em;
  text-shadow: 3px 6px 8px #0c0b2bcf;
}

.video-subtitle {
  position: absolute;
  width: 100%;
  margin: 0 auto;
  top: 45%;
  font-size: 1em;
  font-family: roboto-slab, sans-serif;
  font-weight: 200;
  color: white;
  text-align: center;
  letter-spacing: -0.01em;
  text-shadow: 3px 3px 2px #0a040c;
}

.video-action-container {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  top: 60%;
  display: flex;
  justify-content: center;
}

.video-action-button {
  margin: 0 auto;
  font-size: 1em;
  font-family: roboto-slab, sans-serif;
  font-weight: 300;
  color: white;
  text-align: center;
  letter-spacing: -0.01em;
  box-shadow: -4px 4px 10px #202020;
  border-radius: 4px;
  border: 2px solid var(--red);
  background: var(--red);
  padding: 10px;
  width: fit-content;
}

.video-action-button:hover {
  background-color: #792424;
  border: 2px solid #792424;
  cursor: pointer;
}


.video-title-sodomite {
  color: var(--red);
  font-weight: bold;
  text-shadow: 4px 6px 5px #310c0c;
  font-size: 1.1em;
  margin: -.4% 5px 0 5px;
}

@media (min-width: 600px) {
  .video-title {
    font-size: 2.5em;
    top: 28%;
  }

  .video-subtitle {
    font-size: 1.25em;
  }
}

@media (min-width: 850px) {
  .video-title {
    font-size: 3em;
    top: 32%;
  }

  .video-subtitle {
    font-size: 1.5em;
  }

  .video-action-container {
    top: 52%;
  }

  .video-action-button {
    font-size: 2em;
  }

  .video-container {
    width: calc(100% - 80px);
    margin: 0 auto;
  }
}

@media (min-width: 1150px) {
  .video-title {
    font-size: 5em;
  }

  .video-subtitle {
    font-size: 2em;
  }
}

@media (min-width: 1550px) {
  .video-title {
    font-size: 7em;
  }
}

.landing-page-background {
  background-color: #792424;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0px;
  z-index: -1;
}

.video {
  position: absolute;
  top: 0;
  opacity: 1;
  width: 100%;
  left: 0px;
  display: none;
}

@media (min-width: 850px) {
  .video {
    display:initial;
  } 
}

.landing-page-video {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 56.25%;
  background: black;
  /* 16:9 */
  // height: 0;
}

.landing-page-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header-container {
  display: flex;
  justify-content: center;
  position: sticky;
  align-items: center;
  top: 0;
  background-color: white;
  height: 80px;
  z-index: 9999;
  overflow-y: hidden;
  box-shadow: 0 1px 3px rgb(192, 192, 192);
}

@media (min-width: 1050px) {
  .header-container {
    justify-content: space-between;
  }
}

.header-title-tag {
  text-decoration: none;
}

.header-title {
  text-align: center;
  color: var(--silver);
  font-size: 1.5em;
  font-weight: normal;
  text-decoration: none;
}

.header-title-sodomite {
  font-size: 1.1em;
  color: var(--red);
  font-weight: bold;
  margin: -2px 2px 0 2px;
}

@media (min-width: 550px) {
  .header-title {
    margin-left: 40px;
  }
}

.header-tabs {
  display: none;
}

@media (min-width: 1050px) {
  .header-tabs {
    display: flex;
  }

  .menu-popout {
    display: none;
  }
}

.watch-film {
  background-color: #cf2626;
  color: white !important;
  border-radius: 4px;
}

.header-tabs>a {
  font-size: 1em;
  color: var(--silver);
  margin-right: 10px;
  text-decoration: none;
  cursor: pointer;
  padding: 20px;
  text-align: center;
}

.header-tabs>a:hover {
  background: #831c1c !important;
  color: white;
  border-radius: 4px;
}

.drop-down {
  position: absolute;
  display: block;
  right: 0px;
  top: 80px;
  width: 100%;
  background-color: white;
  box-shadow: 0 1px 3px rgb(192, 192, 192);
}

.drop-down>a {
  display: block;
  width: calc(100% - 40px);
  border-radius: 0;
}

.drop-down>a:hover {
  border-radius: 0;
}

.hc-drop-down {
  overflow: unset;
}

.rumble {
  max-width: 100%;
}

.landing {
  display: none;
}

.landing-inner h1 {
  margin-top: 20px;
  letter-spacing: 0.3em;
  font-size: 3rem;
}

@media (min-width: 1050px) {
  .header-inner h1 {
    justify-content: space-between;
    font-size: 2rem;
  }
}

.menu-popout {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 28px 27.5px 23px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  transition: all .3s;
}

.menu-popout:hover {
  padding: 30px 27.5px 21px;
  background-color: rgb(230, 230, 230);
}

.menu-popout>svg {
  color: var(--silver);
  height: 25px;
  width: 25px;
}


.embed-container-film {
  padding-top: 56.25%;
  background: black;
  position: relative;
}

.embed-container-film>iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.embed-container {
  //background: red;
}

.embed-container>iframe {
  width: 100%;
  height: calc(100vw / 16 * 9);
}

#email-form {
  margin-top: 25px;
  margin-bottom: auto;
  padding: 5px;
  background: rgba(0, 0, 0, 0.9);
}

#email-form input[type="email"] {
  color: #fff;
  background: #000;
  border: outset 2px #222;
  border-radius: 3px;
  padding: 5px 10px;
  margin: 0 5px;
}

#email-form input[type="submit"] {
  cursor: pointer;
  color: #fff;
  background: #000;
  border: outset 2px #222;
  border-radius: 3px;
  padding: 5px 10px;
}

#email-form input[type="submit"]:focus {
  border-style: inset;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30vh;
  padding: 100px 0;
  color: white;
  background: #792424;
}

.arrow-icon {
  cursor: pointer;
  padding: 50px;
  font-size: 50px;

  &:hover {
    color: var(--silver);
  }
}

.review-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-evenly;
  align-items: space-evenly;
  height: 100%;
}

.review-text {
  font-size: 1.5em;
  animation: ease-in-out;
}

.review-author {
  font-size: 2em;
}

.circle-icons-container {
  display: flex;
  justify-content: center;
  font-size: 8px;
  color: gray;
}

.columns-banner-container {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
}

@media (min-width: 550px) {
  .columns-banner-container {
    flex-wrap: nowrap;
    padding: 80px;
  }
}

.column-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
}

.column-text {
  position: absolute;
  z-index: 999;
  color: white;
  font-size: 50px;
  font-family: "Cinzel", serif;
  margin-top: 20%;
  text-shadow: 4px 4px 2px black;
}

.column-text:hover,
.column-text:active {
  text-decoration: underline;
  color: #792424;
}

.column-text>a {
  color: white;
  text-decoration: none;
}

.column-text>a:hover {
  cursor: pointer;
}

.column {
  width: 100%;
  max-height: 675px;
}

.photo-banner-container {
  height: 650px;
  width: 100%;
  display: grid;
  place-items: start center;
  background-color: #090a0e;
  padding-top: 2em;
  position: relative;
  overflow: hidden;
}

.center-text-container {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.film-name {
  color: white;
  z-index: 999;
  font-size: 60px;
  margin-top: 200px;
  font-family: "Cinzel", serif;
}

.pastor-img {
  position: absolute;
  bottom: 0;
  width: 500px;
  opacity: 0.5;
}

@media (min-width: 550px) {
  .pastor-img {
    left: 50px;
  }
}

.watch-text {
  text-decoration: underline;
  color: white;
  font-size: 2em;
}

.watch-container {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 999;
}

footer {
  background-color: #792424;
  color: white;
  padding-top: 1em;
}

footer h2 {
  color: var(--silver);
  text-align: center;
  font-weight: normal;
  text-transform: uppercase;
}

footer .items {
  display: flex;
  flex-wrap: wrap;
  font-size: 1em;
}

footer .items h4 {
  font-size: 1.2em;
}

footer .item {
  box-sizing: border-box;
  padding-left: 4em;
  width: 100%;
}

footer .bottom {
  margin-top: 1em;
  background: #561a1a;
  color: white;
  text-align: center;
  padding: 2em;
}

footer .bottom .icons {
  list-style-type: none;
  display: flex;
  padding: 0;
  max-width: 242px;
  margin: auto;
}

footer .bottom .icon {
  flex-grow: 1;
  text-align: center;
}

footer .bottom .icon svg {
  height: 2em;
  width: auto;
}

footer .bottom .icon a {
  display: block;
  width: min-content;
  padding: 0.3em;
  border: 1px solid transparent;
}

footer svg path {
  fill: white;
}

footer a:hover svg path {
  fill: #575757;
}

footer a:focus {
  border-color: white;
}

@media (min-width: 480px) {
  footer .item {
    width: calc(100% - 4em);
    text-align: center;
    border: none;
  }

  footer .item:first-of-type,
  footer .item:nth-of-type(3) {
    border-left: 0;
  }

  footer .item:last-of-type,
  footer .item:nth-of-type(2) {
    border-right: 0;
  }
}

@media (min-width: 1000px) {

  footer .item {
    width: 50%;
    text-align: left;
    border-left: 0.05em solid var(--silver);
    border-right: 0.05em solid var(--silver);
  }

  footer .item:nth-of-type(3) {
    border-left: 0.05em solid var(--silver);
  }

  footer .item:nth-of-type(2) {
    border-right: 0.05em solid var(--silver);
  }
}

@media (min-width: 1350px) {

  footer .item {
    width: 25%;
    border-left: 0.05em solid var(--silver);
    border-right: 0.05em solid var(--silver);
  }

  .review-text {
    font-size: 3em;
  }
}

.countdown-header {
  color: #d22222;
  font-size: 2em;
}

.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown-item {
  color: #d22222;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;
  margin: 10px;
  padding-top: 10px;
  position: relative;
  width: 100px;
  height: 100px;
}

.countdown-item span {
  color: #b3b3b3;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}

#form-container {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

#rumble {
  width: calc(100% - 40px);
  height: calc((9 / 16) * 100vw - 40px);
  margin: 20px auto;
}

.email-input {
  width: 20px;
}

@media (min-width: 1280px) {
  #rumble {
    width: 1280px;
    height: 720px;
  }
}

.cast-banner {
  display: flex;
  flex-wrap: wrap;
  background-color: #792424;
  text-transform: uppercase;
}

.cast-banner>div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  text-align: center;
}

.cast-banner>div>div {
  padding: 2em;
}

.cast-banner .role {
  color: var(--silver);
  margin: 0;
}

.cast-banner .name {
  color: white;
  font-size: 2em;
  margin: 0;
  margin-top: 0.3em;
}

@media (min-width: 725px) {
  .cast-banner>div {
    width: 50%;
  }
}

.featured-churches {
  background: white;
  padding: 1px 0;
  /* I don't know why, but this makes a big difference */

  article {
    display: flex;
    box-shadow: 3px 3px 17px 3px black;
    padding: 1em;
    margin: 1em;

    img {
      width: 50%;
      height: auto;
      object-fit: contain;
      max-height: 100%;
      filter: brightness(0.9);
      transition: 0.2s;
    }

    &:hover img {
      filter: brightness(1.1);
    }

    &>div {
      width: 50%;
      padding: 1em;
    }

    &:nth-of-type(2n) img {
      order: 1;
    }

    address {
      a {
        padding: 0.2em;
        margin: 0.2em;
        display: flex;
        text-decoration: none;
        align-items: center;

        &:hover {
          text-decoration: underline;
        }

        svg {
          height: 1em;
          width: auto;
          margin-right: 0.5em;
        }
      }

      span,
      pre {
        margin: 0;
        font-family: serif;
      }
    }
  }
}

@media (max-width: 950px) {
  .featured-churches {
    article {
      flex-direction: column;

      &>div,
      img {
        width: 100%;
        order: 1;
      }
    }
  }
}

.about {
  section {
    display: flex;
    height: 140vh auto;

    &:nth-of-type(2n + 1) .content-side {
      order: 2;
    }

    .content-side {
      margin-bottom: 6.3em;
      text-align: center;
      padding: 3em;

      header {
        color: black;

        h2 {
          text-transform: uppercase;
          font-size: 4em;
          font-family: bebas-neue;
          letter-spacing: 0.1em;
          font-weight: lighter;
        }
      }
    }

    .content-side,
    .imageWrapper {
      width: 50%;
      box-sizing: border-box;
    }

    .imageWrapper {
      .img {
        background-size: 50% auto;
        background-attachment: fixed;
        height: 100%;
        background-color: #0b0a0a;

        &.has-img2 {
          height: calc(100vh - 80px);
        }
      }

      .img2 {
        height: calc(40vh + 80px);
        background-size: cover;
      }
    }
  }
}

@media(max-width: 1412px) {
  .imageWrapper {
    width: 100% !important;
    background: white;
  }

  .imageWrapper>.img,
  .imageWrapper>.img2 {
    height: 200px;
    width: 100%;
    background-color: white !important;
    background-repeat: no-repeat;
    background-size: cover !important;
  }

  .about section {
    display: block;
  }

  .about section .content-side {
    width: auto;
    margin-bottom: 0;
  }
}


// News Page Styling

.news-page-header {
  margin-bottom: 0;
}

.news-container {
  padding: 20px;
  text-align: center;
  width: calc(100% - 70px);
  max-width: 0 auto;
  margin: 15px auto;
}

.inner-news {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.news-container h2 {
  text-transform: uppercase;
  font-size: 2em;
  font-family: bebas-neue;
  letter-spacing: 0.1em;
  font-weight: lighter;
  display: flex;
  justify-content: center;
}

.news-container h3 {
  margin: 0;
  font-size: 1.5em;
}

.news-container span {
  display: block;
  padding: 10px 5px;
}

.news {
  border-bottom: 1px solid #eaeaea;
  padding: 20px;
}

.news-date {
  font-style: italic;
  font-size: .9em;
  padding: 0 !important;
}

.news-link {
  display: block;
  bottom: 10px;
  font-size: .8em;
  color: #c13938;
}

.news-link>a:hover {
  cursor: pointer;
}

.year {
  text-decoration: underline;
}

.news-tags {
  display: block;
  color: blue;
}

.news-tag {
  border-radius: 4px;
  padding: 4px;
  margin: 10px;
  display: inline;
  background-color: grey;
  color: white;
  font-size: .6em;
}

.news-tag-Media {
  background-color: #1ea7b7;
}

.news-tag-Protests {
  background-color: #223ea6;
}

.news-tag-Legal {
  background-color: #c92b2b;
}

@media (max-width: 600px) {
  .news-tag {
    align-items: center;
    justify-content: center;
  }
}


// FAQ Page Styling

*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

// .main {
//   justify-content: center;
// }

.faq-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  margin: 15px auto;
  max-width: 1200px;
}

.accordion-item {
  // background-color: #9a9a9a;
  // border-radius: .4rem;
  // margin-bottom: 1rem;
  // padding: 1rem;
  // box-shadow: .5rem 2px .5rem rgba(0,0,0,.1);
  transition: all .3s;
}

.accordion-link {
  font-size: 2rem;
  color: #000000;
  text-transform: uppercase;
  // font-size: 4em;
  font-family: bebas-neue;
  letter-spacing: 0.1em;
  font-weight: lighter;
  text-decoration: none;
  width: calc(100% - 5rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 4rem 2rem 1rem;
  position: relative;
}

.accordion-link:hover {
  background-color: #831c1c !important;
  color: white;
}

.accordion-link>svg {
  position: absolute;
  right: .5rem;
}

.accordion-link .plus-icon {
  // color: rgba(255,255,255,.8);
  padding: .5rem;
}

.accordion-link .minus-icon {
  // color: rgba(255,255,255,.8);
  padding: .5rem;
}

.accordion-item::before {
  display: block;
  content: " ";
  margin-top: -50px;
  height: 100px;
  visibility: hidden;
  pointer-events: none;
}

.answer {
  font-family: "montserrat", "Perpetua", "Gothic A1", sans-serif;
  font-weight: lighter;
  height: 0;
  overflow: hidden;
  position: relative;
  background-color: #9a9a9a;
  color: white;
  transition: all .3s;
}

.answer::before {
  content: "";
  position: absolute;
  width: .6rem;
  height: 90%;
  // background-color: rgb(0, 0, 0);
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.answer p {
  font-size: 1.4rem;
  padding: 2rem;
}

.accordion-item:target .answer {
  height: inherit;
}

.accordion-item:target .accordion-link .plus-icon {
  display: none;
}

.accordion-item:target .accordion-link .minus-icon {
  display: block;
}

.accordion-link .minus-icon {
  display: none;
}

.bible-verse {
  color: #8f2020;
}

//Bible page styling

.verse-container {
  border-bottom: 1px solid #eaeaea;
  padding: 10px;
  text-align: center;
  width: calc(100% - 70px);
  max-width: 0 auto;
  margin: 15px auto;
}

.verse-reference {
  font-size: 1.3rem;
  text-align: center;
  color: #c13939;
  text-decoration: underline;
}

.verse-content {
  text-align: center;
  font-size: 1.1rem;
  word-spacing: .1rem;
  padding: 15px 0;
}